//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiClientSurvey from "../../../../../app/api/authorized/admin/management/ApiClientSurvey";
import DateLib from "../../../../../inc/DateLib";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import Question from "../../../../components/survey/question/question";
import InputWrapper from "../../../../components/input/__wrapper";
import InputText from "../../../../components/inputs/text";
import Select from "../../../../components/inputs/select";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Label from "../../../../components/inputs/label/label";
//Стили
import './styles.css';

export default function SurveyShow({ ...other}){
    return <Page ApiModel={ApiClientSurvey} { ...other}>
      <SurveyShowContent { ...other}/>
    </Page>
}

function SurveyShowContent({ ...other }) {
  const contextPage = useContext(ContextPage);
  const contextContent = useContext(ContextContent);
  const [_id, set_id] = useState(null);
  const [_data, set_data] = useState(null);
  const [survey, set_survey] = useState(null);
  const [_survey_slug, set_survey_slug] = useState(null);
  const [_update_survey, set_update_survey] = useState(null);
  const [_created_by_user, set_created_by_user] = useState(null); 
  const [_updated_by_user, set_updated_by_user] = useState(null); 
  const [_created, set_created] = useState(null);
  const [_updated, set_updated] = useState(null);
  const [user, set_user] = useState(null);
  const [answers, set_answers] = useState({}); // Состояние для хранения ответов
  const [update_temp, set_update_temp] = useState(false);

  //стейты для динамической проверки наличия анкеты и пользователей при создании анкеты
  const [user_email, set_user_email] = useState('');
  const [user_phone, set_user_phone] = useState('');
  const [info, set_info] = useState({ info: '', questionSlug: '' });
  const [link_survey, set_link_survey] = useState(null);

  //стейты для проверки заполнености анкеты детей
  const [child_name, set_child_name] = useState('');
  const [child_surname, set_child_surname] = useState('');
  const [birthday, set_birthday] = useState('');

  const emailPattern = /^(.*?)(@mail\.ru|@yandex\.ru|@inbox\.ru|@google\.com|@gmail\.com|@eprosto\.com)$/;
  const phonePattern = /^7\d{10}$/;

  const surveyTypes = useSelector(state => state.adminOption.surveys);

  contextContent._dataToServer.current = {
    id: _id,
    answers: answers,
    survey_slug: _survey_slug,
  };

  function visibleCreateButtonSwitch(is_visible){
    const createButton = document.querySelector('.content__footer-rigth');
    if(createButton){
      if (is_visible) {
        createButton.style.display = '';
      }else createButton.style.display = 'none';
    }
  }

  useLayoutEffect(() => {
    contextContent._handleSetData.current = (data) => {
      set_id(data.id);
      set_data(data);
      set_survey(data);
      set_survey_slug(data.survey_slug);
      set_update_survey(data.update_survey)
      set_user(data.user);
      set_created_by_user(data.created_by_user);
      set_updated_by_user(data.updated_by_user);
      set_created(data.created_at);
      set_updated(data.updated_at);
      set_update_temp(true);

      contextPage.setTitle(`Анкета клиента:  ${data.client_fullname}`);
      contextContent.setTitle(`Анкета клиента:  ${data.client_fullname}`);
      contextPage.setName(data.client_fullname);
    };
    contextContent.doLoad();
  }, []);
  
  const showSurveyTypesSelect = () => {
    if(!_data)
      return (
        <InputWrapper>
          <Select className="pages__show-input" options={surveyTypes} value={_survey_slug} readOnly={!contextContent.isCreateOrEdit}  onChange={handleSurveyTypeChange}>Анкета</Select>
        </InputWrapper>
      )
  }
  
  useLayoutEffect(() => {
    if(update_temp){
      const fetchData = async () => {
        try {
          const apiClientSurvey = ApiClientSurvey.Show(_survey_slug);
          const responseData = await apiClientSurvey.getResponse();
          if (responseData) {
            set_survey(responseData);
            set_update_temp(false);
          }
        } catch (error) {}
      };
    
      if(_survey_slug) {
        fetchData();
        visibleCreateButtonSwitch(true)
      }
      else{
        set_answers({})
        visibleCreateButtonSwitch(false)
      }
    }
  }, [_survey_slug, survey, update_temp]);

  const handleSurveyTypeChange = (event) => {
    if(_survey_slug === event.target.value) return;
    set_info(prevState => ({
      ...prevState,
      info: '',
      questionSlug: ''
    }));
    set_answers({});
    set_survey(null);
    set_survey_slug(event.target.value);
    set_update_temp(true);
  } 
  
  const getVisibilityClass = (question) => {
    if(question?.isHide === true) {
      return 'question-hidden';
    }
   
    if(!!question.parent_answer_id && !!question.parent_question_id &&
        answers[question.parent_question_id] !== question.parent_answer_id)
    {
      return 'question-hidden';
    }

    return 'question';
  };

  const renderAuthorInfo = () => {
    if(_created_by_user){
      return (
        <>
          <InputWrapper>
            <InputText className="pages__show-input" value={_created_by_user + ' ' + DateLib.formatterDateTime(_created, 'ru')} readOnly={true}>Кем создана</InputText>
          </InputWrapper>
          {_updated_by_user ? 
            <InputWrapper>
              <InputText className="pages__show-input" value={_created_by_user + ' ' + DateLib.formatterDateTime(_updated, 'ru')} readOnly={true}>Кем обновлена</InputText>
            </InputWrapper>
            : null}
        </>
      )
    }
  }

  const renderSurvey = () => {
    if(survey) {
      if (Array.isArray(survey.questions)){
        return (
          <>
            <h2>{survey.name}</h2>
            {info.questionSlug == '' && info.info ? (
                <>
                    <Label className="survey__label-info">{info.info}</Label>
                    {link_survey ? link_survey : null}
                </>
            ) : null}
            {survey.questions.map((question, index) => {
              const visibilityClass = getVisibilityClass(question); // Получаем класс видимости
              let clientAnswer = null;
              if(survey.answers){
                clientAnswer = survey.answers.find(answer => answer.question_id === question.id);
              }

              return (
                <div key={index} className={visibilityClass}>
                  <Question 
                    clientAnswer={clientAnswer}
                    question={question} 
                    updateFlag={_update_survey}
                    info={info}
                    user={user}
                    readOnly={!contextContent.isCreateOrEdit}
                    onUserFieldChange={(questionSlug, value) => handleInfoFieldChange(questionSlug, value)}
                    onOtherChange={(id, value) => handleRadioAnswerChange(id, value)} 
                    onOtherInputChange={(id, value) => handleInputAnswerChange(id, value)} 
                  />
                </div>
              );
            })}
          </>
        );
      }
    } 
    return null;
  };

  const parentNamePhoneReadonly = (flag) => {
    const updatedQuestions = survey.questions.map((question) => {
      if (question.id === 24 || question.id === 26) {
        return {
          ...question, 
          readonly: flag
        };
      }
      return question;
    });
  
    set_survey(prevSurvey => ({
      ...prevSurvey,
      questions: updatedQuestions
    }));
    set_info(prevState => ({
      ...prevState,
      info: '',
      questionSlug: ''
    }));

    return null;
  }

  const insertParentInfo = (data) => {
    let updatedAnswers = [];
    survey.questions.map((question, index) => {
      if(question.id == 24) {
        updatedAnswers.push({
          question_id: question.id,
          value: data[0].name
        });
      }

        if(question.id == 26) {
          updatedAnswers.push({
            question_id: question.id,
            value: data[0].surname
          });
        }
    });
    if(updatedAnswers.length){
      set_survey(prevSurvey => ({
        ...prevSurvey,
        answers: updatedAnswers
      }));
    }
  }

  const clearParentInfo = () => {
    let updatedAnswers = [];
    survey.questions.map((question, index) => {
      if(question.id == 24) {
        updatedAnswers.push({
          question_id: question.id,
          value: null
        });
      }

        if(question.id == 26) {
          updatedAnswers.push({
            question_id: question.id,
            value: null
          });
        }
    });

    if(updatedAnswers.length){
      set_survey(prevSurvey => ({
        ...prevSurvey,
        answers: updatedAnswers
      }));
    }
  }

  useLayoutEffect(() => {
    if((user_email || user_phone) && !_update_survey){
      fetchUserData()
    }else 
      set_info(prevState => ({
        ...prevState,
        info: '',
        questionSlug: ''
      }));
      set_link_survey(null)
  }, [user_email, user_phone])

  useLayoutEffect(() => {
    if(child_name && child_surname && birthday){
      checkChildSurveyExists()
    }else{
      set_info(prevState => ({
        ...prevState,
        info: '',
        questionSlug: ''
      }));
      set_link_survey(null);
    }
  }, [child_name, child_surname, birthday])

  const fetchUserData = async () => {
    try{
      let data = await ApiClientSurvey.GetUsers({ 'email': user_email, 'phone': user_phone });
      parseResponse(data);
    }catch(error){
        console.error('Ошибка при получении данных:', error);
    }
  };

  const removeSpaces = (str) => {
    return str.replace(/\s+/g, '');
  };

  const checkChildSurveyExists = async () => {
    set_info(prevState => ({
      ...prevState,
      info: '',
      questionSlug: ''
    }));
    set_link_survey(null);
    if(_survey_slug == 'flight-participant-survey-child'){
      const fullName = removeSpaces(child_surname + child_name).toLowerCase();
      console.log(fullName)
      let surveyClients = await fetchClientSurveyData();
      surveyClients.map((surveyClient, index) => {
        if(surveyClient.survey_slug == _survey_slug && (removeSpaces(surveyClient.client_fullname).toLowerCase() == fullName && surveyClient.birthday == birthday)){
          let link = contextContent.checkPermission('show') ? 
            <a href={contextPage.routes.show.url({ id: surveyClient.id })}><VisibilityIcon style={{fill: 'rgb(80 157 207)' }}/></a> 
            : null;
          set_info(prevState => ({
            ...prevState,
            info: 'Для данного клиента анкета уже заполнена',
            questionSlug: ''
          }));
          set_link_survey(link);
        }
      })  
    }
  }

  const checkSurveyExists = async () => {
    let surveyClients = await fetchClientSurveyData();
    surveyClients.map((surveyClient, index) => {
      if(surveyClient.survey_slug == _survey_slug && (removeSpaces(surveyClient.client_email).toLowerCase() == removeSpaces(user_email).toLowerCase() || surveyClient.client_phone == user_phone)){
        let link = contextContent.checkPermission('show') ? 
          <a href={contextPage.routes.show.url({ id: surveyClient.id })}><VisibilityIcon style={{fill: 'rgb(80 157 207)' }}/></a> 
          : null;
          set_info(prevState => ({
            ...prevState,
            info: 'Для данного клиента анкета уже заполнена',
            questionSlug: ''
          }));
          set_link_survey(link);
      }
    })
  }

  const fetchClientSurveyData = async () => {
    try{
      let getData = ApiClientSurvey.Index();
      let surveyData = await getData.getResponse();
      if(surveyData) return surveyData;
      return null;
    }catch(error){
        console.error('Ошибка при получении данных:', error);
    }
  }

  const parseResponse = async (data) => {
    set_info(prevState => ({
      ...prevState,
      info: '',
      questionSlug: ''
    }));
    set_link_survey(null);
    if(!data.length) {
      if(_survey_slug === 'flight-participant-survey-child'){
        clearParentInfo();
        parentNamePhoneReadonly(false);
      }
    }
   
    if(data.length == 1){
      if(_survey_slug === 'flight-participant-survey'){
        checkSurveyExists();
      } 

      if(_survey_slug === 'flight-participant-survey-child'){
        if(user_email && user_phone){
          insertParentInfo(data);
          parentNamePhoneReadonly(true);
        }
      }
    }
  
    if(data.length > 1){
      if(_survey_slug === 'flight-participant-survey-child'){
        clearParentInfo();
        parentNamePhoneReadonly(false);
        set_info(prevState => ({
          ...prevState,
          info: 'Не удается однозначно определить клиента-родителя',
          questionSlug: 'parent-phone'
        }));
      }
      if(_survey_slug === 'flight-participant-survey'){
        set_info(prevState => ({
          ...prevState,
          info: 'Не удается однозначно определить клиента',
          questionSlug: 'phone'
        }));
      }
    }

    if(!user_email || !user_phone){
      clearParentInfo();
      parentNamePhoneReadonly(false);
    }
  }

  const handleInfoFieldChange = (questionSlug, value) => {
    if(questionSlug == 'phone' || questionSlug == 'parent-phone'){
      if(phonePattern.test(value)){
        set_user_phone(value);
      }else{
        set_user_phone('');
      }
    }
    if(questionSlug == 'email' || questionSlug == 'parent-email'){
      if(emailPattern.test(value)){
        set_user_email(value);
      }else{
        set_user_email('');
      }
    }
    if(questionSlug == 'child-name'){
      set_child_name(value);
    }
    if(questionSlug == 'child-surname'){
      set_child_surname(value);
    }
    if(questionSlug == 'birthday'){
      set_birthday(value);
    }
  }

  const handleInputAnswerChange = (questionId, value) => {
    if(value && value.trim() != ''){
      set_answers((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: value,
      }));
    }else{
      set_answers((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: null,
      }));
    }
  };

  const handleRadioAnswerChange = (questionId, value) => {
    if(value && value != 'freeAnswer' && value != 'Не заполнено') {
      set_answers((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: Number(value),
      }));
    }else{
      set_answers((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: value,
      }));
    }
  };

  return <>
    {showSurveyTypesSelect()}
    {renderAuthorInfo()}
    {renderSurvey()}
  </>;
}

//Реакт
import React, {useState} from "react";
//Компоненты
import Label from "../label";
import MessageError from "../../message/__errors";
//Mui icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
//Стили
import '../styles.css';
import './styles.css';

export default function InputPassword({children, value='', placeholder, errors, className = '', ...other}){
    const [_isHidden, set_isHidden] = useState(true);
    if(placeholder === undefined) placeholder = children;
    return <>
        <Label>{children}</Label>
        <div className="password__input-container">
            <input
                {...other}
                type={_isHidden ? 'password' : 'text'}
                className={`input input-password ${className}`}
                placeholder={placeholder}
                value={value}
            />
            <div class="hide-icon" onClick={() => {set_isHidden(!_isHidden)}}>
                {_isHidden 
                    ? <VisibilityIcon/>
                    : <VisibilityOffIcon/>
                }
            </div>
        </div>
        {errors ? <MessageError errors={errors} sx={{width: "100%"}}/> : null}
    </>
}